<template>
  <div :class="`wrapper  wrapper--${bu} m-3 p-2`">
    <div class="text-center p-2">
      <h2 class="text-center">Afleveringen per week</h2>
      <span @click="previousWeek"><i class="fas fa-arrow-left"></i></span>
      <span class="text-center m-2"
        >Week: {{ week_number }}, {{ year_number }}</span
      >
      <span @click="nextWeek"><i class="fas fa-arrow-right"></i></span>
    </div>
    <Loading v-if="loading" />
    <div>
      <Tabs
      >
        <Tab title="Totaal">
          <table :class="`w-100 table--perweek table__border--${bu}`">
            <thead :class="`table__head--${bu}`">
              <tr>
                <th class="table__head--text">Verkoper</th>
                <th class="table__head--text">Aantal</th>
                <th class="table__head--text">Stadagen</th>
                <th class="table__head--text">Inkopen</th>
                <th class="table__head--text">Kosten</th>
                <th class="table__head--text">Commissie</th>
                <th class="table__head--text">Verkopen</th>
                <th class="table__head--text">Winst</th>
                <th class="table__head--text">Percentage</th>
                <th class="table__head--text">Afschrijving</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item,
                key) in deliveries_per_person_total.deliveries_total"
                :key="key"
                :class="`table__row--color`"
              >
                <td class="table__cell--default">{{ item.naam }}</td>
                <td class="table__cell--default table__data--right">
                  {{ item.aantal }}
                </td>
                <td class="table__cell--default table__data--right">
                  {{ item.stadagen }}
                </td>
                <td class="table__cell--default table__data--right">
                  € {{ item.inkopen }}
                </td>
                <td class="table__cell--default table__data--right">
                  € {{ item.kosten }}
                </td>
                <td class="table__cell--default table__data--right">
                  € {{ item.commisie }}
                </td>
                <td class="table__cell--default table__data--right">
                  € {{ item.verkopen }}
                </td>
                <td class="table__cell--default table__data--right">
                  € {{ item.winst }}
                </td>
                <td class="table__cell--default table__data--right">
                  {{ item.winstperc }} %
                </td>
                <td class="table__cell--default table__data--right">
                  € {{ item.afschrijving }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr class="table__foot">
                <th>Totaal:</th>
                <th class="table__data--right table__foot--padding">
                  {{ deliveries_per_person_total.deliveries_total_sum.aantal }}
                </th>
                <th class="table__data--right table__foot--padding">
                  {{
                    deliveries_per_person_total.deliveries_total_sum.stadagen
                  }}
                </th>
                <th class="table__data--right table__foot--padding">
                  €
                  {{ deliveries_per_person_total.deliveries_total_sum.inkopen }}
                </th>
                <th class="table__data--right table__foot--padding">
                  €
                  {{ deliveries_per_person_total.deliveries_total_sum.kosten }}
                </th>
                <th class="table__data--right table__foot--padding">
                  €
                  {{
                    deliveries_per_person_total.deliveries_total_sum.commissie
                  }}
                </th>
                <th class="table__data--right table__foot--padding">
                  €
                  {{
                    deliveries_per_person_total.deliveries_total_sum.verkopen
                  }}
                </th>
                <th class="table__data--right table__foot--padding">
                  € {{ deliveries_per_person_total.deliveries_total_sum.winst }}
                </th>
                <th class="table__data--right table__foot--padding">
                  {{
                    deliveries_per_person_total.deliveries_total_sum.percent
                  }}
                  %
                </th>
                <th class="table__data--right table__foot--padding">
                  €
                  {{
                    deliveries_per_person_total.deliveries_total_sum
                      .afschrijving
                  }}
                </th>
              </tr>
            </tfoot>
          </table>
        </Tab>
        <Tab
          v-for="(seller,
          key) in deliveries_per_week_per_person.deliveries_per_person"
          :key="key"
          :title="key"
        >
          <table :class="`w-100 table--perweek table__border--${bu}`">
            <thead :class="`table__head--${bu}`">
              <tr>
                <th class="table__head--text">Foto</th>
                <th class="table__head--text">Item</th>
                <th class="table__head--text">Klant</th>
                <th class="table__head--text">Dagen</th>
                <th class="table__head--text">Inkopen</th>
                <th class="table__head--text">Kosten</th>
                <th class="table__head--text">Commissie</th>
                <th class="table__head--text">Verkopen</th>
                <th class="table__head--text">Winst</th>
                <th class="table__head--text">Afschrijving</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, key) in seller"
                :key="key"
                :class="`table__row--color`"
              >
                <td class="table__cell--default">
                  <img
                    :src="
                      `https://apps.kleyn.com:1919/prod/public/data/mainimage/${item.nummer}/original`
                    "
                    class="table__picture--max-width"
                  />
                </td>
                <td class="table__cell--default">
                  <div>
                    <ImageHover :itemnummer="item.nummer" :bu="bu" /><br />
                    {{ item.soortnaam }}<br />
                    {{ item.opbouwnaam }}<br />
                    {{ item.merktype }}<br />
                    {{ item.regjaar }}
                  </div>
                </td>
                <td class="table__cell--default">
                  <div>
                    <router-link
                      :to="{ name: 'klantpagina', params: { id: item.klant } }"
                    >{{ item.klant }}</router-link><br />
                    {{ item.klantnaam }}<br />
                    {{ item.adres }}<br />
                    {{ item.stad }}<br />
                    {{ item.landnaam }}
                  </div>
                </td>
                <td class="table__cell--default table__data--right">
                  {{ item.stadagen }}
                </td>
                <td class="table__cell--default table__data--right">
                  € {{ item.inkoopbedrag }}
                </td>
                <td class="table__cell--default table__data--right">
                  € {{ item.kostenbedrag }}
                </td>
                <td class="table__cell--default table__data--right">
                  € {{ item.commisiebedrag }}
                </td>
                <td class="table__cell--default table__data--right">
                  € {{ item.verkoopbedrag }}
                </td>
                <td class="table__cell--default table__data--right">
                  € {{ item.winst }}
                </td>
                <td class="table__cell--default table__data--right">
                  € {{ item.afschrijving }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr class="table__foot">
                <th class="table__foot--padding">Totaal:</th>
                <th></th>
                <th></th>
                <th class="table__data--right table__foot--padding">
                  {{
                    deliveries_per_week_per_person.deliveries_per_person_sum[
                      key
                    ].stadagen
                  }}
                </th>
                <th class="table__data--right table__foot--padding">
                  €
                  {{
                    deliveries_per_week_per_person.deliveries_per_person_sum[
                      key
                    ].inkopen
                  }}
                </th>
                <th class="table__data--right table__foot--padding">
                  €
                  {{
                    deliveries_per_week_per_person.deliveries_per_person_sum[
                      key
                    ].kosten
                  }}
                </th>
                <th class="table__data--right table__foot--padding">
                  €
                  {{
                    deliveries_per_week_per_person.deliveries_per_person_sum[
                      key
                    ].commissie
                  }}
                </th>
                <th class="table__data--right table__foot--padding">
                  €
                  {{
                    deliveries_per_week_per_person.deliveries_per_person_sum[
                      key
                    ].verkopen
                  }}
                </th>
                <th class="table__data--right table__foot--padding">
                  €
                  {{
                    deliveries_per_week_per_person.deliveries_per_person_sum[
                      key
                    ].winst
                  }}
                </th>
                <th class="table__data--right table__foot--padding">
                  €
                  {{
                    deliveries_per_week_per_person.deliveries_per_person_sum[
                      key
                    ].afschrijving
                  }}
                </th>
              </tr>
            </tfoot>
          </table>
        </Tab>
      </Tabs>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading.vue";
import request from "@/functions/request.js";
import moment from "moment";
import ImageHover from "@/components/ImageHover.vue";
import { subtractTime, addTime } from "@/functions/date.js";
import Tabs from "@/components/Tabs/Tabs.vue";
import Tab from "@/components/Tabs/Tab.vue";

export default {
  props: ["bu"],
  components: { Loading, ImageHover, Tabs, Tab },
  data: () => ({
    loading: null,
    deliveries_per_person_total: null,
    deliveries_per_week_per_person: null,
    full_date: null,
    year_number: null,
    week_number: null,
  }),
  created() {
    this.full_date = moment();
    this.getData(this.bu);
  },
  watch: {
    bu(newbu) {
      this.getData(newbu);
    },
  },
  methods: {
    getData(bu) {
      this.loading = true;
      this.year_number = this.full_date.year();
      this.week_number = this.full_date.week();

      request(
        `deliveries-per-week-per-bu/${bu}/${this.year_number}/${this.week_number}`,
        "GET"
      ).then(
        ({ deliveries_per_week_per_person, deliveries_per_person_total }) => {
          this.deliveries_per_week_per_person = deliveries_per_week_per_person;
          this.deliveries_per_person_total = deliveries_per_person_total;
          this.loading = false;
        }
      );
    },

    previousWeek() {
      this.full_date = subtractTime(7, this.full_date, "days");
      this.getData(this.bu);
    },

    nextWeek() {
      this.full_date = addTime(7, this.full_date, "days");
      this.getData(this.bu);
    },
  },
};
</script>
